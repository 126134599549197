import PropTypes from 'prop-types';
import React from 'react';
import '../../styles/components/profiles/ProfilePic.scss';

const ProfilePic = ({url, alt, addClass, inAgentCard, lazyLoad}) => {
  const side = !!inAgentCard ? 84 : 60;

  const style = {
    width: side,
    height: side,
    maxWidth: side,
    maxHeight: side,
  };

  return !!lazyLoad ? (
    <img
      data-src={url}
      alt={alt}
      className={`lazyload profile-pic ${addClass || ''}`}
      style={style}
    />
  ) : (
    <img src={url} className={`profile-pic ${addClass || ''}`} style={style} alt={alt} />
  );
};

ProfilePic.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
  addClass: PropTypes.string,
  inAgentCard: PropTypes.bool,
  lazyLoad: PropTypes.bool,
};

export default ProfilePic;
